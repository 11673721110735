<template>
  <v-container>
    <!-- ************************ tabs  ******************************* -->
    <!-- <v-layout row>
      <div class="col-12 col-md-3">
        <v-dialog v-model="upload" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="nav-btn ma-1"
              outlined
              large
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon large>mdi-upload </v-icon>آپلود فایل
            </v-btn>
          </template>
          <v-flex>
              <v-card>
            <v-col md="12">
                <v-card-text>
                  <h4 style="color: white">فایل مرجع</h4>
                  <v-file-input
                    v-model="refInput"
                    ref="refInput"
                    show-size
                    :rules="excelFileRules"
                    accept=".xls, .xlsx"
                    label="حداکثر 5 مگابایت - فقط xls , xlsx"
                  ></v-file-input>
                  <h4 style="color: white">ورودی دیجیکالا</h4>
                  <v-file-input
                    v-model="digiInput"
                    ref="digiInput"
                    show-size
                    :rules="excelFileRules"
                    accept=".xls, .xlsx"
                    label="حداکثر 5 مگابایت - فقط xls , xlsx"
                  ></v-file-input>
                  <v-btn @click="uploadFiles"  :loading='loading' color="primary">ارسال</v-btn>
                </v-card-text>

            </v-col>
              </v-card>
          </v-flex>
        </v-dialog>
      </div>
      <div class="col-12 col-md-3">
        <a href="/api/download" target="_blank" rel="noopener noreferrer">
        <v-btn
            class="nav-btn ma-1"
            width="100%"
            color="primary"
            outlined
            large
            dark
          >
            <v-icon>mdi-download</v-icon>دانلود فایل
          </v-btn></a>
      </div>
      <router-view />
    </v-layout>
     -->
    <!-- ***************************************** -->
    <v-layout>
      <v-row>
        <v-progress-linear
          :active="loading"
          indeterminate
          color="yellow darken-2"
          class="w-100"
        ></v-progress-linear>
        <v-card class="w-100">
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab v-for="item in sheetNames" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-card>
      </v-row>
    </v-layout>
    <v-layout>
      <v-row>
        <v-data-table :headers="[...headers,{
            text: 'حذف',
            value: 'actions',
            sortable: false,
          }]"  :search="search" :items="myItem" class="elevation-1" >
          <template v-slot:top>
            <v-card-title>
              <v-text-field
               v-model="search"
        append-icon="mdi-magnify"
        label="جستجو ..."
        single-line
        hide-details
              ></v-text-field>
            </v-card-title>
            <v-toolbar flat>
              <v-toolbar-title
                >لیست محصولات: {{ sheetNames[tab] }}</v-toolbar-title
              >
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  @click="editedItem={}"
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    سطر جدید
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row >
                        <v-col v-for="item in headers" :key="item.value" cols="12" sm="6" >
                          <v-text-field
                            v-model="editedItem[item.value]"
                            :label= item.value
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="close">
                      انصراف
                    </v-btn>
                    <v-btn :loading='loading' color="green darken-1" text @click="save">
                      ذخیره
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >آیا از حذف کالا مطمئن هستید؟</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogDelete = false"
                      >انصراف</v-btn
                    >
                    <v-btn color="red darken-1"  :loading='loading' text @click="deleteItemConfirm"
                      >بله</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="deleteItem(item)" color="red">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: [],
  mounted() {
    this.shItems(0);
  },
  data: () => ({
    loading: true,
    search: "",
    dialog: false,
    upload: false,
    dialogDelete: false,
    refInput: null,
    digiInput: null,
    itemsList: [],
    headersList: [],
    headers: [],
    myItem: [],
    sheetNames: [],
    editedIndex: -1,
    editedItem: {

    },
    defaultItem: {
  
    },
    tab: 0,
    excelFileRules: [
      (value) =>
        !value ||
        value.size <= 5000000 ||
        "حجم فایل باید کمتر از 5 مگابایت باشد!",
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "کالای جدید" : "Edit Item";
    },
  },
  watch: {
    tab(val) {
      this.shItems(val);
    },
  },
  created() {
    this.initialize();
  },

  methods: {
    // Insert Excel file Into DB
    uploadFiles() {
      // send xls file to DB with post
      let list = new FormData();
      if (!this.refInput) {
        alert("لطفا  فایل مرجع را انتخاب کنید!");
        return;
      } else if (!this.digiInput) {
        alert("لطفا فایل ورودی دیجی کالا را انتخاب کنید");
        return;
      }
      list.append("refInput", this.refInput);
      list.append("digiInput", this.digiInput);
      this.loading = true;

      // ersal form data be samt server
      this.$axios
        .post("api/uploadFile", list)
        .then(({ data }) => {
          alert(data);
          this.upload=false
        })     .finally((e) => (this.loading = false))
        .catch((e) => {
          console.log(e);
        });
      // clear file input
      this.refInput = null;
      this.digiInput = null;
    },
    shItems(item) {
      this.loading = true;
      this.myItem = [];
      this.$axios
        .get("api/list/" + item)
        .then(({ data }) => {
          this.myItem = data.data;
          this.headers = data.headers;
          this.headers.push();
          this.sheetNames = data.sheetList;
        })
        .finally((e) => (this.loading = false))
        .catch((e) => {
          console.log(e);
        });
    },
    initialize() {
      this.myItem = this.itemsList;
      this.headers = this.headersList;
    },
    deleteItem(item) {
      this.editedIndex = this.myItem.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    deleteItemConfirm(itemCode) {
      this.loading = true;
      const params = {};
      this.$axios
        .delete("api/list/" + this.tab, {
          data: { itemCode: this.myItem[this.editedIndex]["کد تنوع"] },
        })
        .then(({ data }) => {
          this.$nextTick(() => {
            this.myItem.splice(this.editedIndex, 1)
            this.shItems(this.tab)
            // this.myItem = Object.assign({}, this.defaultItem);
            // this.editedIndex = -1;
            this.dialogDelete = false;
          });
        })
        .finally((e) => (this.loading = false))
        .catch((e) => {
          console.log(e);
        });
    },
    save() {
      this.loading = true;
   
      this.$axios
        .post("api/newItem/" + this.tab, this.editedItem)
        .then(({ data }) => {
            this.shItems(this.tab)

          this.close();
        })
        .finally((e) => (this.loading = false))
        .catch((e) => {
          console.log(e);
        });
      // if (this.editedIndex > -1) {
      //   Object.assign(this.myItem[this.editedIndex], this.editedItem);
      // } else {
      //   this.myItem.push(this.editedItem);
      // }
    },
    dlFile(){
      this.$axios
      .post("/api/download")
        .then(({ data }) => {
          window.open(data, "_blank");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
};
</script>
<style>
.v-file-input {
  margin-top: 0px;
  margin-right: 15px;
  width: 100%;
}
.body {
  width: 100%;
}
.v-text-field {

  margin-right: 15px;
  width: 100%;
}
.v-btn {
  letter-spacing: 0;
}
.nav-btn {
  width: 96.2%;
}
#cancel {
  margin-left: 15px;
}
.projecList {
  width: 100%;
}
.downloadBtn {
  width: 100%;
  margin-top: 10px;
  margin-left: 6%;
  text-decoration: none;
}
.tabelsHeader {
  min-width: 550px;
}
.changeProjectName {
  margin-left: 5%;
}
.w-100 {
  width: 100%;
}
</style>
